import { useEffect } from 'react';

import { PERIODS_IDS } from 'constants/periods';
import { WIDGETS } from 'constants/widgets';
import { useAppDispatch, useAppSelector } from 'store';
import { selectUserHoroscopeV2, selectHasDataToGenerateUserHoroscopeV2 } from 'store/horoscope/selectors';
import { loadUserHoroscopeV2, getSummaryContent, processDoDontPredictions } from 'store/horoscope/actions';
import { selectUserProfileIsLoaded } from 'store/profile/selectors';
import { processDailyTipsPredictions } from 'store/daily-tips/actions';

const useLoadUserHoroscopesV2 = () => {
  const dispatch = useAppDispatch();

  const enableHoroscopesV2 = useAppSelector(state => state.remoteConfig.remoteConfigParams?.summaryWidgetConfig?.enableHoroscopesV2);
  const advisorHoroscopeEnabled = useAppSelector(state => state.remoteConfig.remoteConfigParams?.advisorHoroscopeEnabled);
  const hasDataToGenerate = useAppSelector(selectHasDataToGenerateUserHoroscopeV2);
  const todayHoroscope = useAppSelector(state => selectUserHoroscopeV2(state, PERIODS_IDS.TODAY));
  const tomorrowHoroscope = useAppSelector(state => selectUserHoroscopeV2(state, PERIODS_IDS.TOMORROW));
  const systemLocale = useAppSelector(state => state.app.initialSystemLocale);
  const todayWidgets = useAppSelector(state => state.remoteConfig.remoteConfigParams.todayWidgets);
  const todayContentDoDontEnabled = useAppSelector(state => state.remoteConfig.remoteConfigParams.todayContentDoDontEnabled);
  const todayContentTipsEnabled = useAppSelector(state => state.remoteConfig.remoteConfigParams.todayContentTipsEnabled);
  const userProfileIsLoaded = useAppSelector(selectUserProfileIsLoaded);
  const predictions = useAppSelector(state => state.lunarCalendar.predictions);

  const appIsReady = userProfileIsLoaded && !!systemLocale;
  const todayContentWidgetsEnabled = [WIDGETS.DAILY_TIPS, WIDGETS.DO_DONT].some(w => todayWidgets.includes(w));
  const todayContentEnabled = todayContentWidgetsEnabled && (todayContentDoDontEnabled || todayContentTipsEnabled);
  const summaryWidgetEnabled = todayWidgets.includes(WIDGETS.SUMMARY);
  const todayHoroscopeCanBeLoaded = !todayHoroscope && (enableHoroscopesV2?.day || todayContentEnabled);
  const tomorrowHoroscopeCanBeLoaded = enableHoroscopesV2?.day && todayHoroscope && !tomorrowHoroscope;

  useEffect(() => {
    if (appIsReady && todayHoroscopeCanBeLoaded && hasDataToGenerate && !advisorHoroscopeEnabled) {
      dispatch(loadUserHoroscopeV2(PERIODS_IDS.TODAY));
    }
  }, [appIsReady, dispatch, hasDataToGenerate, todayHoroscopeCanBeLoaded, advisorHoroscopeEnabled]);

  useEffect(() => {
    if (appIsReady && tomorrowHoroscopeCanBeLoaded && hasDataToGenerate && !advisorHoroscopeEnabled) {
      dispatch(loadUserHoroscopeV2(PERIODS_IDS.TOMORROW));
    }
  }, [appIsReady, dispatch, hasDataToGenerate, tomorrowHoroscopeCanBeLoaded, advisorHoroscopeEnabled]);

  useEffect(() => {
    if (appIsReady && todayContentWidgetsEnabled && !summaryWidgetEnabled && !!predictions.length) {
      if (todayHoroscope && !advisorHoroscopeEnabled) {
        dispatch(getSummaryContent(PERIODS_IDS.TODAY));
        return;
      }

      dispatch(processDoDontPredictions());
      dispatch(processDailyTipsPredictions());
    }
  }, [appIsReady, dispatch, summaryWidgetEnabled, todayContentWidgetsEnabled, todayHoroscope, advisorHoroscopeEnabled, predictions]);
};

export default useLoadUserHoroscopesV2;
