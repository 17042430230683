import { createAction } from 'redux-actions';
import { getMessaging as getFirebaseMessaging, getToken as getMessagingToken } from 'firebase/messaging';

import Analytics from 'analytics';
import { AppDispatch, AppGetState, AppThunk } from 'store';
import { setFCMToken } from 'api/notifications';
import { NotificationType, TYPES } from 'store/notification/types';
import { navigateToAdvisorByPushNotification } from 'store/navigation/actions';
import { TOP_TAB_IDS } from 'screens/advisors/constants';
import { selectAnAdvisor } from 'store/astrologers/catalog/actions';
import { isStandalone } from 'utils/pwa';

const setFcmTokenHash = createAction(TYPES.SET_FCM_TOKEN_HASH);
const setPermissionRequested = createAction(TYPES.SET_PERMISSION_REQUESTED);
const setWebNotificationRequestOverlayVisibility = createAction(TYPES.SET_WEB_NOTIFICATION_REQUEST_OVERLAY_VISIBILITY);
export const setDataFromPush = createAction(TYPES.SET_DATA_FROM_PUSH);

export const setNotificationTime = () => {
  return async (_dispatch: AppDispatch) => {};
};

export const initNotifications = () => {
  return async (_dispatch: AppDispatch) => {};
};

export const isNotificationAvailableToShow = () => {
  return async (_dispatch: AppDispatch) => {};
};

export const notificationsRequest = (): AppThunk => {
  return (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { notificationsOnStart, notificationsOnDashboardSession },
      },
    } = getState();

    if (!isStandalone()) {
      return false;
    }

    const sessionNumber = (Analytics.getSessionNumber() ?? 0) + 1;
    const permission = Notification.permission;

    if (permission === 'granted') {
      dispatch(getToken());
    } else if (permission === 'default' && (notificationsOnStart || sessionNumber === notificationsOnDashboardSession)) {
      Analytics.track('Push_WebRequestOverlay_Show');
      dispatch(setWebNotificationRequestOverlayVisibility(true));
    }
  };
};

export const getToken = () => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      notification: { isPermissionRequested },
    } = getState();

    try {
      // Registration of a service worker on the firebase side and receipt of a token key
      const messaging = getFirebaseMessaging();

      // A temporary solution for registering/replacing a service worker, since an error occurs on the firebase side when the app is launched for the first time
      // https://github.com/firebase/firebase-js-sdk/issues/7693#issuecomment-1849813958
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');

      const fcmToken = await getMessagingToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_CLOUD_MESSAGING_TOKEN,
        serviceWorkerRegistration: registration,
      });

      dispatch(updateFcmToken(fcmToken));
    } catch (e) {
      Analytics.track('Push_Request_Error', { error: String(e) });
      console.log('> request notification permissions error', e);
    }

    if (!isPermissionRequested) {
      dispatch(setPermissionRequested());
      Analytics.track('Push_PermissionWeb_Requested');
    }

    dispatch(setWebNotificationRequestOverlayVisibility(false));
  };
};

export const updateFcmToken = (fcmToken: string) => {
  return async (dispatch: AppDispatch, getState: AppGetState) => {
    const {
      remoteConfig: {
        remoteConfigParams: { remoteNotificationsEnabled },
      },
      auth: { id },
    } = getState();

    // just dont send token
    if (!remoteNotificationsEnabled) {
      return false;
    }

    try {
      if (id) {
        setFCMToken(fcmToken);
        dispatch(setFcmTokenHash(`${id}:${fcmToken}`));
      }
    } catch (e) {
      console.log('> update fcmToken error', e);
    }
  };
};

export const handleNotificationWebOpen = (payload: NotificationType) => {
  return (dispatch: AppDispatch) => {
    const { astrologer_id: advisorId, type, body: message } = payload;

    if (type === 'horoscope') {
      Analytics.track('Push_AdvisorHoroscope_Navigate', { advisorId });
      return dispatch(selectAnAdvisor(advisorId, TOP_TAB_IDS.HOROSCOPE));
    }

    dispatch(navigateToAdvisorByPushNotification(advisorId, message));
  };
};
